import { createSignal } from "solid-js";
import { CloseX, Search } from "#icons";
import cn from "classnames";
import { tagular } from "#cohesion/tagular";

interface TypesenseSearchBoxProps {
  classNames?: {
    root?: string;
    input?: string;
  };
  label: string;
  id: string;
  searchUrl?: string;
}

export function TypesenseSearchBox(props: TypesenseSearchBoxProps) {
  const { classNames, label, id, searchUrl } = props;

  const params = new URLSearchParams(searchUrl);
  const initialQueryValue = params.get("q") || "";
  const [inputValue, setInputValue] = createSignal(initialQueryValue);

  const handleInputChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    setInputValue(target.value);
  };

  const handleReset = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();

    // Reset the input value
    setInputValue("");

    // Remove all search parameters from the URL and navigate to /search
    const url = new URL(window.location.href);
    url.search = "";
    window.history.replaceState({}, document.title, url.pathname);

    // Optionally, reload the page to reflect the changes
    window.location.reload();
  };

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    inputValue();

    // Append the new query param to the URL from the search page
    params.set("q", inputValue());
    // Reload and fetch new data
    window.location.href = `/search?${params.toString()}`;
    tagular("Search", {
      rawSearchQuery: inputValue(),
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      onReset={handleReset}
      class={cn("relative", classNames?.root)}
      role="search"
      noValidate
    >
      <label for={id} class="sr-only">
        {label}
      </label>
      <input
        id={id}
        type="search"
        auto-complete="off"
        auto-correct="off"
        autoCapitalize="off"
        spell-check={false}
        maxLength={512}
        name="q"
        class={cn(
          "appearance-none focus:outline-hidden font-light bg-white block border-b border-solid border-black-200 leading-normal py-2 pl-2 pr-8 w-full text-lg placeholder:text-black-200",
          classNames?.input,
        )}
        onInput={handleInputChange}
        value={inputValue()}
      />
      <div class="absolute right-2 top-0 bottom-0 flex space-x-1">
        {inputValue() && (
          <button type="reset" class="text-md p-2" aria-label="Reset">
            <CloseX
              focusable="false"
              aria-label="Reset"
              className="inline-block text-black"
              width="15"
              height="15"
            />
          </button>
        )}
        <button type="submit" class="text-md p-2" aria-label="Search">
          <Search
            focusable="false"
            aria-label="Search"
            className="inline-block text-blue"
            width="15"
            height="15"
          />
        </button>
      </div>
    </form>
  );
}
