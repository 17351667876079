import { createSignal } from "solid-js";
import cn from "classnames";

interface TypesenseRadioSortProps {
  searchUrl?: string;
}

const sortByFilters = [
  {
    id: "places",
    value: "places",
    label: "Destination",
  },
  {
    id: "pois",
    value: "pois",
    label: "Things to Do",
  },
  {
    id: "articles",
    value: "articles",
    label: "Stories",
  },
];

export function TypesenseRadioSort(props: TypesenseRadioSortProps) {
  const { searchUrl } = props;

  const params = new URLSearchParams(searchUrl);
  const initialSortByValue = params.get("sortBy") || "";

  const [active, setActive] = createSignal(
    initialSortByValue || sortByFilters[0].id
  );

  const handleSortChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const selectedSortBy = target.value;

    setActive(selectedSortBy);

    // Append the new query param to the URL from the search page
    params.set("sortBy", selectedSortBy);
    // Remove the page number from the URL
    // to set to page back to the first page
    // for the new content type
    params.delete("page");
    // Reload and fetch new data
    window.location.search = params.toString();
  };

  return (
    <form class="sm:flex sm:items-center sm:space-y-0 sm:space-x-8 mt-4">
      {sortByFilters.map(option => (
        <div class="relative flex items-center p-2">
          <input
            id={option.id}
            name="sortBy"
            type="radio"
            checked={active() === option.id}
            class="focus:ring-black text-sm h-4 w-4 text-black border-black-300 mr-2"
            onInput={handleSortChange}
            value={option.id}
          />
          <label
            class={cn(
              "fill-bg text-sm",
              active() === option.id ? "text-black" : "text-black-400"
            )}
            for={option.id}
          >
            {option.label}
          </label>
        </div>
      ))}
    </form>
  );
}
