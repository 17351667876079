import { withIcon } from "#icons/_withIcon";

export const Search = withIcon(
  () => (
    <path d="M15.3542 14.646L11.9312 11.224C13.028 9.94433 13.5865 8.28959 13.4896 6.60701C13.3926 4.92443 12.6476 3.34483 11.411 2.19971C10.1744 1.05458 8.54232 0.432967 6.85726 0.465313C5.1722 0.49766 3.56517 1.18145 2.37343 2.37319C1.1817 3.56493 0.497904 5.17196 0.465557 6.85702C0.433211 8.54208 1.05483 10.1742 2.19995 11.4108C3.34507 12.6474 4.92467 13.3923 6.60725 13.4893C8.28983 13.5863 9.94457 13.0278 11.2242 11.931L14.6462 15.354C14.7401 15.4478 14.8674 15.5006 15.0002 15.5006C15.133 15.5006 15.2603 15.4478 15.3542 15.354C15.4481 15.2601 15.5008 15.1327 15.5008 15C15.5008 14.8672 15.4481 14.7398 15.3542 14.646ZM1.5002 6.99996C1.5002 5.91216 1.82277 4.84879 2.42712 3.94432C3.03147 3.03985 3.89045 2.3349 4.89544 1.91862C5.90044 1.50234 7.0063 1.39342 8.0732 1.60564C9.14009 1.81786 10.1201 2.34168 10.8893 3.11087C11.6585 3.88006 12.1823 4.86007 12.3945 5.92696C12.6067 6.99386 12.4978 8.09972 12.0815 9.10472C11.6653 10.1097 10.9603 10.9687 10.0558 11.573C9.15137 12.1774 8.088 12.5 7.0002 12.5C5.542 12.4984 4.14398 11.9184 3.11287 10.8873C2.08176 9.85618 1.50179 8.45816 1.5002 6.99996Z" />
  ),
  {
    viewBox: "0 0 16 16",
  },
);
export const SearchFill = withIcon(
  () => (
    <path
      d="M15.5298 14.4701L12.2688 11.2081C13.3487 9.85693 13.8703 8.14356 13.7263 6.41988C13.5824 4.6962 12.7839 3.09307 11.4948 1.9398C10.2057 0.786517 8.52391 0.170643 6.7949 0.218677C5.06589 0.266711 3.4209 0.975006 2.19783 2.19808C0.974762 3.42114 0.266467 5.06613 0.218433 6.79514C0.170399 8.52416 0.786273 10.2059 1.93955 11.495C3.09283 12.7841 4.69596 13.5826 6.41964 13.7266C8.14332 13.8705 9.85669 13.349 11.2078 12.2691L14.4698 15.5301C14.612 15.6625 14.8 15.7347 14.9943 15.7312C15.1886 15.7278 15.374 15.6491 15.5114 15.5117C15.6489 15.3743 15.7276 15.1889 15.731 14.9946C15.7344 14.8003 15.6623 14.6122 15.5298 14.4701ZM1.74982 7.00006C1.74982 5.96171 2.05772 4.94667 2.6346 4.08332C3.21148 3.21996 4.03142 2.54705 4.99073 2.14969C5.95004 1.75233 7.00564 1.64837 8.02404 1.85094C9.04244 2.05351 9.9779 2.55352 10.7121 3.28775C11.4464 4.02198 11.9464 4.95744 12.1489 5.97584C12.3515 6.99424 12.2475 8.04984 11.8502 9.00915C11.4528 9.96846 10.7799 10.7884 9.91656 11.3653C9.0532 11.9422 8.03817 12.2501 6.99982 12.2501C5.60792 12.2485 4.27348 11.6948 3.28926 10.7106C2.30504 9.72639 1.7514 8.39196 1.74982 7.00006Z"
      fill="currentColor"
    />
  ),
  {
    viewBox: "0 0 16 16",
  },
);
